<template>
  <div>
    <SystemHead page-type="sign" />
    <div class="form-signup mb-5 mx-auto">
      <h4>{{ $t("sign.reset_pass") }}</h4>
      <el-form
        :model="formData"
        :rules="rules"
        ref="resetForm"
        :disabled="disabled"
      >
        <el-form-item :label="$t('sign.sign_text4') + '：'" prop="username">
          <el-input v-model="formData.username"></el-input>
        </el-form-item>
      </el-form>
      <button
        :disabled="disabled"
        type="button"
        class="btn btn-primary"
        @click="submitForm('resetForm')"
      >
        {{ $t("sign.sign_text5") }}
      </button>
      <div class="clearfix py-3">
        <router-link to="/">{{ $t("sign.sign_text6") }}</router-link>
      </div>
    </div>
    <footer class="footer mt-auto py-2 fixed-bottom text-center">
      <div class="container">
        <small class="d-block text-muted"
          >@2019 Brainlabel.org. All rights reserved.</small
        >
      </div>
    </footer>
  </div>
</template>

<script>
import SystemHead from "../../layouts/systemHead";
import { login } from "../../api/api";
import { MessageBox, Loading } from "element-ui";

export default {
  components: {
    SystemHead,
  },
  data() {
    return {
      formData: {
        username: "",
      },
      disabled: false,
    };
  },
  computed: {
    rules() {
      return {
        username: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSubmit();
        } else {
          return false;
        }
      });
    },
    async handleSubmit() {
      let submitData = {
        username: this.formData.username,
        type: "forgot",
      };
      let _self = this;
      _self.disabled = true;
      try {
        let res = await login(submitData);
        if (res.status === 200) {
          this.$helper.successNotify(
            res.message,
            function () {
              _self.$router.push({
                name: "newPassword",
                query: { email: res.data },
              });
            },
            3000
          );
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      } finally {
        setTimeout(() => {
          _self.disabled = false;
        }, 3000);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.form-signup {
  margin-top: 80px;
  max-width: 700px;
}
</style>
